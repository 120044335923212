@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4 !important;
}


.hide-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.react-time-picker__wrapper {
    border: 0 !important;
}